import React, { useState, useEffect, useRef } from "react"
import styled, { keyframes } from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { useTransition, animated } from "react-spring"

export default function EmailListForm() {
  const [email, setEmail] = useState("")
  const [status, setStatus] = useState("")
  const [message, setMessage] = useState("")

  const inputEl = useRef(null)

  const [showAlert, setAlert] = useState(false)
  const transitions = useTransition(showAlert, null, {
    from: { transform: "scale(0.9)", opacity: 0 },
    enter: { opacity: 1, transform: "scale(1)" },
    leave: { opacity: 0, transform: "scale(0.9)" },
  })

  const [count, setCount] = useState(0)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCount(0)
      setAlert(false)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [count])

  const handleSubmit = async event => {
    event.preventDefault()

    // Mailchimp always responds with status code 200, accompanied by a string indicating the result of the response.
    const { result, msg } = await addToMailchimp(email)

    if (result === "success") {
      setEmail("")
      inputEl.current.value = ""
    }
    // Removes the HTML returned in some response messages in case of error
    setMessage(msg.split("<")[0])

    setCount(1)
    setAlert(true)
    setStatus(result)
  }

  const handleChange = event => setEmail(event.target.value)

  return (
    <React.Fragment>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <StatusMessage
              success={status === "success" ? "true" : "false"}
              key={key}
              style={props}
            >
              {message}
            </StatusMessage>
          )
      )}

      <SubscribeForm onSubmit={handleSubmit}>
        <SubscribeElements>
          <SubscribeInput
            placeholder="Your email address"
            name="email"
            type="text"
            ref={inputEl}
            onChange={handleChange}
          />
          <SubscribeButton type="submit">Join the family</SubscribeButton>
        </SubscribeElements>
      </SubscribeForm>
    </React.Fragment>
  )
}

const SubscribeForm = styled.form`
  width: 100%;
  @media (min-width: 768px) {
  }
`

const SubscribeElements = styled.div`
  display: flex;

  flex-direction: column;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 1rem;
  }

  @media (min-width: 1440px) {
  }
`

const SubscribeInput = styled.input`
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  display: block;
  border: 0;
  border-bottom: 2px solid var(--tertiaryColourFade);
  padding: 0.25rem 0;
  color: var(--tertiaryColour);
  background: transparent;
  transition: background-color 150ms ease-in-out, color 150ms ease-in-out;

  width: 100%;

  margin-bottom: 1rem;

  font-size: 1rem;

  ::placeholder {
    color: var(--tertiaryColourFade);
    opacity: 1;
  }

  @media (min-width: 768px) {
    color: var(--primaryColour);
    border-color: var(--primaryColourFade);
    width: auto;
    margin: 0;

    ::placeholder {
      color: var(--primaryColour);
      opacity: 0.5;
    }
  }

  @media (min-width: 1024px) {
    font-size: 1.125rem;
    padding: 0.25rem 0;
  }

  &:hover {
  }
  &:focus {
  }
`

const SubscribeButton = styled.button`
  display: inline-block;
  font-family: "Manrope", sans-serif;
  border: 0;
  outline: 0;

  height: 2.5rem;

  font-size: 1rem;
  padding: 0.25rem 1rem;

  background-color: var(--tertiaryColour);
  color: var(--primaryColour);
  font-weight: 700;

  transition: background-color 150ms ease-in-out, color 150ms ease-in-out;

  &:hover,
  &:focus {
    background-color: var(--primaryColourFeint);
  }

  @media (min-width: 768px) {
    background-color: var(--primaryColour);
    color: var(--tertiaryColour);

    &:hover,
    &:focus {
      background-color: var(--secondaryColour);
      color: var(--tertiaryColour);
    }
  }

  @media (min-width: 1024px) {
    font-size: 1.125rem;
    padding: 0.25rem 1rem;
  }
`

const alertAnimation = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const StatusMessage = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  /* transition: opacity 150ms ease-in-out; */

  color: ${props =>
    props.success === "true"
      ? "var(--secondaryColour)"
      : "var(--warningColour)"};
  background-color: var(--primaryColourFeint);
  width: 100%;
  padding: 0.75rem 0.5rem;
  text-align: center;
  font-size: 1rem;

  @media (min-width: 768px) {
    padding: 0;
    background-color: transparent;
    position: absolute;
    top: 0;

    left: auto;
    width: auto;
  }
`
