import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import EmailListForm from "../components/subscribe"
import Bird from "../components/bird"

export default function Home() {
  return (
    <React.Fragment>
      <HeroPanel>
        <Helmet>
          <title>Muuli - Sustainable Kidswear Made Locally</title>
        </Helmet>
        <BirdPrimary>
          <Bird />
        </BirdPrimary>
        <BirdLeft>
          <Bird />
        </BirdLeft>
        <BirdRight>
          <Bird />
        </BirdRight>
        <ContentWrap>
          <TextBlock>
            <Logo>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 298 59"
              >
                <g fill="#96BFCB" clipPath="url(#clip0)">
                  <path d="M63.08 57.5H45.34V25.22L31.46 41.07 17.74 25.46V57.5H0V0h18.56l13.06 14.95L44.68 0h18.4v57.5zM102.83 58.89c-20.78-.25-27.27-6.74-27.52-27.52V0h17.74v30.97c0 9.2 1.81 11.01 11.01 11.01h2.47c9.2 0 11.01-1.81 11.01-11.01V0h17.74v31.38c-.25 20.78-6.74 27.27-27.52 27.52h-4.93v-.01zM174.61 58.89c-20.78-.25-27.27-6.74-27.52-27.52V0h17.74v30.97c0 9.2 1.81 11.01 11.01 11.01h2.47c9.2 0 11.01-1.81 11.01-11.01V0h17.74v31.38c-.25 20.78-6.74 27.27-27.52 27.52h-4.93v-.01zM269.56 57.5h-50.27V0h17.74v40.74h32.53V57.5zM280.15 57.5V0h17.74v57.5h-17.74z"></path>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <path fill="#fff" d="M0 0H297.89V58.89H0z"></path>
                  </clipPath>
                </defs>
              </svg>
            </Logo>
            <EmailLink href="mailto:hello@muuli.com.au">
              hello@muuli.com.au
            </EmailLink>
            <Tagline>Sustainable kidswear made&nbsp;locally</Tagline>
          </TextBlock>
        </ContentWrap>
      </HeroPanel>
      <SubscribeBlock>
        <EmailListForm />
      </SubscribeBlock>
    </React.Fragment>
  )
}

const HeroPanel = styled.div`
  position: relative;
  height: 100%;
`
const ContentWrap = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.5rem;

  @media (min-width: 768px) {
    padding: 2rem;
  }
`
const BirdPrimary = styled.div`
  position: absolute;
  top: 0;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  & > svg {
    width: 100%;
    fill: var(--secondaryColour);
    max-width: 80%;
    max-height: 60%;
  }

  /* Landscape */
  @media (min-aspect-ratio: 9/5) {
    opacity: 1;
    & > svg {
    }
  }

  /* Portrait */
  @media (max-aspect-ratio: 6/9) {
    opacity: 1;
    transform: translateY(-50%) translateX(-20%);
    width: 150%;

    & > svg {
      transform: translateY(-15%);
    }
  }
`
const BirdLeft = styled.div`
  position: absolute;
  top: 0;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-45%) translateY(-5%);

  & > svg {
    width: 100%;
    fill: var(--secondaryColour);
    max-width: 80%;
    max-height: 60%;
  }

  /* Landscape */
  @media (min-aspect-ratio: 9/5) {
    transform: translateY(-60%) translateX(-45%);
    & > svg {
    }
  }

  /* Portrait */
  @media (max-aspect-ratio: 6/9) {
    transform: translateY(5%) translateX(-35%);
    width: 150%;

    & > svg {
    }
  }
`

const BirdRight = styled.div`
  position: absolute;
  top: 0;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(45%) translateY(-5%);

  & > svg {
    width: 100%;
    fill: var(--secondaryColour);
    max-width: 80%;
    max-height: 60%;
  }

  /* Landscape */
  @media (min-aspect-ratio: 9/5) {
    transform: translateY(-50%) translateX(45%);
    & > svg {
    }
  }

  /* Portrait */
  @media (max-aspect-ratio: 6/9) {
    transform: translateY(-20%) translateX(35%);
    width: 150%;

    & > svg {
    }
  }
`

const TextBlock = styled.main``

const Logo = styled.div`
  margin-bottom: 1rem;
  height: 2.25rem;

  & > svg {
    height: 100%;
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1440px) {
    margin-bottom: 1.5rem;
    height: 3.5rem;
  }
`

const EmailLink = styled.a`
  display: block;
  color: var(--primaryColour);
  text-decoration: none;
  font-weight: 600;

  transition: color 150ms ease-in-out;

  font-size: 1rem;
  margin-bottom: 0.25rem;

  @media (min-width: 768px) {
  }

  @media (min-width: 1440px) {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  &:hover {
    color: var(--secondaryColour);
  }
`

const Tagline = styled.p`
  display: block;
  color: var(--primaryColour);
  font-weight: 600;
  font-size: 1rem;

  @media (min-width: 768px) {
  }

  @media (min-width: 1440px) {
    font-size: 1.5rem;
  }
`

const SubscribeBlock = styled.div`
  background-color: var(--primaryColour);
  display: flex;

  padding: 2.5rem 1.5rem;

  @media (min-width: 768px) {
    position: absolute;
    background-color: transparent;
    width: 55vw;
    right: 0;
    bottom: 0;

    padding: 1.5rem;
  }

  @media (min-width: 1440px) {
    width: 35rem;
  }
`
